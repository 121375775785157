import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { nameToBackgroundColor, nameToBorderColor } from 'components/collection-page/collection-colors'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionProductCategories } from 'interfaces/page-section-product-categories'
import { IUiResources } from 'interfaces/ui-resource'
import { InfoProductCategory } from './info-product-category'
import { MedicineProductCategory } from './medicine-product-category'
import ArrowNavigation from 'components/arrow-navigation'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

interface IProps {
    section: IPageSectionProductCategories
    uiResources: IUiResources
}

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <h2 className="section--header">{children}</h2>,
}

const rxCategoryInternalName = 'Rx'
const allCategoryInternalName = 'All'

const cardWidthMobile = 240
const cardWidthDesktop = 374

export const PageSectionProductCategories = ({ section, uiResources }: IProps): React.ReactElement => {
    const { header, categoriesCollection } = section ?? {}
    const categories = categoriesCollection.items

    const [scrollableContainerEl, setScrollableContainerEl] = React.useState<HTMLDivElement>()
    const scrollableContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setScrollableContainerEl(node)
        }
    }, [])

    const isMobileScreen = useMobileScreenDetection()

    const [activeCategoryId, setActiveCategoryId] = React.useState(categories[0].sys.id)

    const activeCategory = categoriesCollection.items.find((category) => category.sys.id === activeCategoryId)

    const renderTabPanel = () => {
        // Check internalName for Rx -- this category does not have medicines,
        // used as just an informational section
        if (activeCategory.internalName === rxCategoryInternalName) {
            return (
                <InfoProductCategory
                    productCategory={activeCategory}
                    cardCtaLabel={uiResources['rxProductCategorySectionGenericCardButtonLabel'].value}
                    cardDescription={uiResources['rxProductCategorySectionGenericCardDescription'].value}
                    cardImage={uiResources['rxProductCategorySectionGenericCardTitleAndImage'].media}
                    cardTitle={uiResources['rxProductCategorySectionGenericCardTitleAndImage'].value}
                    cardCtaHrefLanding={uiResources['rxProductCategorySectionLandingCardCtaHref'].value}
                />
            )
        }

        if (activeCategory.internalName === allCategoryInternalName) {
            // merge medicine from all products
            const allMedicines = []

            categories.forEach((category) => {
                if (category.internalName === allCategoryInternalName) {
                    return
                }

                category.linkedFrom.medicineCollection.items.forEach((medicine) => allMedicines.push(medicine))
            })

            activeCategory.linkedFrom.medicineCollection.items = allMedicines

            return (
                <MedicineProductCategory
                    productCategory={activeCategory}
                    fullDrugFactsLabel={uiResources['fullDrugFactsLabel'].value}
                    ctaLabel={uiResources['addToCartButtonText'].value}
                    ctaDisabledLabel={uiResources['outOfStockButtonLabel'].value}
                    rxCardCtaLabel={uiResources['rxProductCategorySectionRxCardButtonLabel'].value}
                    genericForLabel={uiResources['genericForLabel'].value}
                    cardCtaHrefEligibility={uiResources['rxProductCategorySectionGenericCardCtaHref'].value}
                />
            )
        }

        return (
            <MedicineProductCategory
                productCategory={activeCategory}
                fullDrugFactsLabel={uiResources['fullDrugFactsLabel'].value}
                ctaLabel={uiResources['addToCartButtonText'].value}
                ctaDisabledLabel={uiResources['outOfStockButtonLabel'].value}
                rxCardCtaLabel={uiResources['rxProductCategorySectionRxCardButtonLabel'].value}
                genericForLabel={uiResources['genericForLabel'].value}
                cardCtaHrefEligibility={uiResources['rxProductCategorySectionGenericCardCtaHref'].value}
            />
        )
    }

    return (
        <section className="section--v2">
            {header?.json && (
                <ContentfulRichText content={header.json} renderNode={headerRenderNode} ignoreMarkdownStyles />
            )}

            <div className="flex justify-between space-x-4 items-center mt-2.5 lg:mt-6">
                {/* tab buttons */}
                <nav role="tablist" aria-label="todo: use ui resource">
                    <ul className="flex flex-wrap -mt-2 -ml-2 lg:-mt-6 lg:-ml-6">
                        {categories.map((category) => {
                            const { name, sys, internalName } = category
                            const isActive = sys.id === activeCategoryId
                            const activeClasses = ` ${nameToBackgroundColor[internalName]} ${nameToBorderColor[internalName]} text-white `
                            const inActiveClasses = ` border-white `
                            return (
                                <li key={sys.id} className="flex-shrink-0 mt-4 ml-2 lg:mt-6 lg:ml-6">
                                    <button
                                        className={`px-3.5 py-1.5 rounded-full text-sm font-bold border ${
                                            isActive ? activeClasses : inActiveClasses
                                        }`}
                                        id={`${name}-tab`}
                                        role="tab"
                                        aria-controls={`${name}-panel`}
                                        aria-selected="false"
                                        onClick={() => {
                                            setActiveCategoryId(sys.id)
                                            scrollableContainerEl.scrollLeft = 0
                                        }}
                                    >
                                        {name}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                {!isMobileScreen && (
                    <ArrowNavigation
                        scrollableContainerEl={scrollableContainerEl}
                        cardWidth={isMobileScreen ? cardWidthMobile : cardWidthDesktop}
                    />
                )}
            </div>

            {/* tab panels */}
            <div
                ref={scrollableContainerRef}
                className="mt-5 lg:mt-6 overflow-auto snap-x scrollbar-hidden bleed-scroll--make_element_flex flex"
                style={{
                    ['--cardWidthMobile' as string]: cardWidthMobile + 'px',
                    ['--cardWidthDesktop' as string]: cardWidthDesktop + 'px',
                }}
            >
                {renderTabPanel()}
            </div>
        </section>
    )
}
