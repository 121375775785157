/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import styles from './style.module.css'
import Link from 'next/link'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import Loader from 'components/loading/loader'
import Video from 'components/video/video'
import globalManifest from 'data/global-manifest.json'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { IUiResource } from 'interfaces/ui-resource'

interface IProps {
    section: IGenericPageSection
    playMediaLabel: IUiResource
}

const { resources: uiResources } = globalManifest
const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <h2 className="section--header hero--header leading-10 lg:leading-13 tracking-tight">{children}</h2>
        ),
    },
}

const subHeaderRenderOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
            <p className="text-sm lg:text-xl font-bold tracking-wider uppercase">{children}</p>
        ),
    },
}

const paragraphRenderOptions = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <p className="text-base lg:text-xl">{children}</p>,
    },
}

// for lack of a better name
const TwoColumnsWithImageAlignSection = ({ section, playMediaLabel }: IProps): React.ReactElement => {
    const {
        header,
        richSubHeader,
        paragraphCopy,
        mediaCollection,
        mobileMediaCollection,
        align: alignImage,
        primaryCtaLink,
        videoPosterImage,
        videoPosterImageMobile,
    } = section

    const [isRedirecting, setIsRedirecting] = React.useState(false)

    const sectionImageDesktop = mediaCollection?.items?.[0]
    const sectionImageMobile = mobileMediaCollection?.items?.[0]

    const poster = videoPosterImage?.url || videoPosterImageMobile?.url

    return (
        <section className={`section ${styles['two-columns-with-token-image-section']}`}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-9">
                <div
                    className={`sm:w-2/3 md:w-full mx-auto ${
                        alignImage?.toLowerCase() === DIRECTION_HORIZONTAL.RIGHT ? 'lg:order-1' : ''
                    }`}
                >
                    {(sectionImageDesktop || sectionImageMobile) && (
                        <>
                            {sectionImageDesktop?.contentType === 'video/mp4' ||
                            sectionImageMobile?.contentType === 'video/mp4' ? (
                                <Video
                                    className="w-full object-cover"
                                    height={sectionImageDesktop?.height || sectionImageMobile?.height}
                                    width={sectionImageDesktop?.width || sectionImageMobile?.width}
                                    autoPlay
                                    loop
                                    playsInline
                                    muted
                                    poster={poster ? poster + '?fm=jpg&w=420' : undefined}
                                    playMediaLabel={playMediaLabel.value}
                                >
                                    <source
                                        src={sectionImageDesktop?.url || sectionImageMobile?.url}
                                        type="video/mp4"
                                    />
                                    <span>{uiResources?.['browserDoesNotSupportVideoMessage']?.value}</span>
                                </Video>
                            ) : (
                                <picture>
                                    {/* only provide desktop options if desktop image is defined */}
                                    {sectionImageDesktop && (
                                        <>
                                            {/* large screens */}
                                            {/* webp */}
                                            <source
                                                type="image/webp"
                                                media={`(min-width: ${lgBreakPoint}px)`}
                                                srcSet={`
                                                    ${sectionImageDesktop.url}?w=1024&fm=webp&q=75 1024w,
                                                    ${sectionImageDesktop.url}?w=1440&fm=webp&q=75 1440w,
                                                    ${sectionImageDesktop.url}?w=1600&fm=webp&q=75 1600w
                                                `}
                                                sizes="(min-width: 1024px) 50vw, 100vw"
                                            />
                                            <source
                                                type="image/jpeg"
                                                media={`(min-width: ${lgBreakPoint}px)`}
                                                srcSet={`
                                                    ${sectionImageDesktop.url}?w=1024&fm=jpg&q=75 1024w,
                                                    ${sectionImageDesktop.url}?w=1440&fm=jpg&q=75 1440w,
                                                    ${sectionImageDesktop.url}?w=1600&fm=jpg&q=75 1600w
                                                `}
                                                sizes="(min-width: 1024px) 50vw, 100vw"
                                            />
                                        </>
                                    )}
                                    {/* only provide mobile options if mobile image is defined */}
                                    {sectionImageMobile && (
                                        <>
                                            {/* small screens */}
                                            {/* webp */}
                                            <source
                                                type="image/webp"
                                                srcSet={`
                                                    ${sectionImageMobile.url}?w=420&fm=webp&q=75 420w,
                                                    ${sectionImageMobile.url}?w=640&fm=webp&q=75 640w
                                                `}
                                            />
                                            <source
                                                type="image/jpeg"
                                                srcSet={`
                                                    ${sectionImageMobile.url}?w=420&fm=jpg&q=75 420w,
                                                    ${sectionImageMobile.url}?w=640&fm=jpg&q=75 640w
                                                `}
                                            />
                                        </>
                                    )}

                                    {/* fallback image -  priority to desktop image */}
                                    <img
                                        width={sectionImageDesktop?.width || sectionImageMobile?.width}
                                        alt={sectionImageDesktop?.title || sectionImageMobile?.title}
                                        height={sectionImageDesktop?.height || sectionImageMobile?.height}
                                        src={`${
                                            sectionImageDesktop?.url || sectionImageMobile?.url
                                        }?w=1024&fm=jpg&q=75`}
                                        className="w-full object-cover"
                                        loading="lazy"
                                    />
                                </picture>
                            )}
                        </>
                    )}
                </div>
                <div className="flex flex-col justify-center">
                    {/* header */}
                    <div className={styles['header']}>
                        <ContentfulRichText content={header?.json} renderOptions={headerRenderOptions} />
                    </div>
                    <div className="mt-6">
                        <ContentfulRichText content={richSubHeader?.json} renderOptions={subHeaderRenderOptions} />
                    </div>
                    <div className="mt-5">
                        <ContentfulRichText content={paragraphCopy?.json} renderOptions={paragraphRenderOptions} />
                    </div>
                    {primaryCtaLink?.href && (
                        <div className="flex justify-center md:justify-start">
                            <Link
                                href={primaryCtaLink?.href}
                                onClick={() => setIsRedirecting(true)}
                                className="button button-primary mt-6 lg:mt-10 inline-block min-w-250"
                            >
                                {isRedirecting ? <Loader /> : primaryCtaLink?.name}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default TwoColumnsWithImageAlignSection
